<template>
  <div>
      <div class="px-3">
      <label for="button-text" class="editor-label">Checkbox options:</label>
      <input type="text" v-model="block.text"  placeholder="Choose the best opportunity..." name="button-text" class="editor-input-control w-100">
    <div class="mt-2" v-if="Object.prototype.hasOwnProperty.call(block, 'isRequired')">
      <label for="" class=" mt-2 editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Required field:</span>
        <b-form-checkbox
                       :id="`isRequired-${Date.now()}`"
                v-model="block.isRequired"
                  ></b-form-checkbox>
      </label>
    </div>
    <div class="mt-2"   v-if="Object.prototype.hasOwnProperty.call(block, 'preselectCheckbox')">
      <label for="" class=" mt-2 editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Pre-Select:</span>
        <b-form-checkbox
                :id="`preselect-${Date.now()}`"
                v-model="block.preselectCheckbox"
                @change="handlePreselect($event)"
                  ></b-form-checkbox>
      </label>
    </div>
    </div>
     <hr>
     <div class="px-3">
      <div class="">
        <label for="button-text" class="editor-label">Link:</label>
        <input type="url" v-model="block.link"  placeholder="Choose the best opportunity..." name="button-text" class="editor-input-control w-100">
      </div>
      <div class="mt-2">
        <label for="button-text" class="editor-label">Link text:</label>
        <input type="url" v-model="block.linkText"  placeholder="Choose the best opportunity..." name="button-text" class="editor-input-control w-100">
      </div>
     </div>
     <hr>
     <div class="px-3">
  <FontFamily  v-model="block.style.fontFamily"></FontFamily>
  <div class="my-3 d-flex gap align-items-center" title="Font size (px)">
    <div class="d-flex align-items-center" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span>
  <input type="number" v-model="block.style.fontSize" placeholder="Font size" class="editor-input-control w-100 input-bg-color border-0">
</div>
    <FontStyles :textBold.sync="block.style.fontWeight" 
  :textItalic.sync=" block.style.fontStyle"
  :textUnderLine.sync="block.style.textDecoration" class=""></FontStyles>
   
  </div>
  <div class="d-flex gap align-items-center">
    <TextAlign v-model=block.style.textAlign ></TextAlign>
    <div class="">
    <label for=""></label>
    <div class="d-flex align-items-center" title="Stroke Rotate" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.925 7.10834L4.75 5.92501C4 6.89168 3.53333 8.00834 3.39166 9.16668H5.075C5.19166 8.44168 5.48333 7.73334 5.925 7.10834ZM5.075 10.8333H3.39166C3.53333 11.9917 3.99166 13.1083 4.74166 14.075L5.91666 12.8917C5.48333 12.2667 5.19166 11.5667 5.075 10.8333ZM5.91666 15.2667C6.88333 16.0167 8.00833 16.4667 9.16666 16.6083V14.9167C8.44166 14.7917 7.74166 14.5083 7.11666 14.0583L5.91666 15.2667ZM10.8333 3.39168V0.833344L7.04166 4.62501L10.8333 8.33334V5.07501C13.2 5.47501 15 7.52501 15 10C15 12.475 13.2 14.525 10.8333 14.925V16.6083C14.125 16.2 16.6667 13.4 16.6667 10C16.6667 6.60001 14.125 3.80001 10.8333 3.39168Z" fill="currentColor"></path></svg></span>
  <input type="number" min="0"  v-model="block.style.strokeRotation" placeholder="Rotate" class="editor-input-control w-100 input-bg-color border-0">
</div>
  </div>
</div>

 </div>
 <hr>
 <div class="px-3">
  <SelectColor v-model="block.style.color"  :opacity.sync="block.style.textOpacity" class="mt-3" :label="'Text Color'"></SelectColor>
  <SelectColor v-model="block.style.strokeColor" :opacity.sync="block.style.strokeOpacity"  :label="'Link Color:'"></SelectColor>
</div>
<hr>
<div class="px-3 mb-3">
  <padding  v-if="isDesktopView" :right.sync="block.style.marginRight" :left.sync="block.style.marginLeft" :top.sync="block.style.marginTop" :bottom.sync="block.style.marginBottom"></padding>
  <padding  v-else :label="'Spacing (Mobile)'"  :right.sync="block.style.mobileMarginRight" :left.sync="block.style.mobileMarginLeft" :top.sync="block.style.mobileMarginTop" :bottom.sync="block.style.mobileMarginBottom"></padding> 
</div>

  </div>
</template>

<script>
import fontFamilyList from "../../../staticPages/fontFamilyList";
import FontFamily from "../MiniComponents/FontFamily.vue";
import FontStyles from "../MiniComponents/FontStyles.vue";
import Padding from "../MiniComponents/Padding.vue";
import SelectColor from "../MiniComponents/SelectColor.vue";
import TextAlign from "../MiniComponents/TextAlign.vue";

export default {
  components:{
    FontFamily,
    FontStyles,
    TextAlign,
    SelectColor,
    Padding

  },
  props: {
    block: Object,
    index: Number,
    isDesktopView: Boolean,
    QuestionPageType: String,
  },
  computed: {
    getFontFamilyList() {
      return fontFamilyList;
    },
    textBlockFont(){
      return this.block.style.fontFamily
    },
  },
  methods: {
      handlePreselect() {
        this.block.value = this.block.preselectCheckbox
      },
    },
    watch:{
    textBlockFont(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    }
  }
};
</script>

<style>
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}

</style>
